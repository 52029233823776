'use strict';

angular.module('windmanagerApp')
  .directive('viewBlade', function() {
    return {
      templateUrl: 'components/directives/blade/viewBlade/viewBlade.html',
      restrict: 'EA',
      scope: {
        blade: '=',
        findings: '='
      },
      controller: 'ViewBladeCtrl',
      controllerAs: 'vbc',
      link: function(scope, element, attrs) {
      }
    };
  });
